<template>
  <div
    :class="[
      'dragon-fire-wrapper',
      'content',
      isBuyingTicket ? 'animate-coins' : '',
    ]"
  >
    <div class="bg"></div>
    <video :class="['coins']" autoplay muted loop>
      <source src="../../assets/coins-falling.mp4" type="video/mp4" />
    </video>
    <GenericLoading v-if="isLoadingInitialData" />
    <template v-else>
      <div class="header">
        <h1 class="title">Dragon Fire Lottery</h1>
        <h2 class="main-prize">
          Win
          <template v-if="isErc20Round">
            {{ prizeQuantity }} {{ prizeName }}
          </template>
          <template v-else-if="isErc721Round">
            {{ nftData.name }}
          </template>
          <template v-else-if="isErc1155Round && nftData">
            {{ prizeQuantity }} {{ nftData.name }}
          </template>
        </h2>
      </div>
      <div class="top-area">
        <div class="left">
          <div class="image">
            <img
              v-if="isErc20Round && prizeName === 'DCAU'"
              src="../../assets/dcau-large.png"
              alt="DCAU"
            />
            <img
              v-else-if="isErc20Round && prizeName === 'DCAR'"
              src="../../assets/dcar-large.png"
              alt="DCAR"
            />
            <div
              class="cover-image"
              v-else-if="(isErc721Round || isErc1155Round) && nftData"
              :style="`background-image: url(${nftData.image})`"
            ></div>
          </div>
          <div>
            <h2 class="prize">
              <span>DCAR Pool</span> {{ dcarPool.toFixed(2) }}<br />
            </h2>
            <h2 class="prize">
              <span>DCAU Pool</span> {{ dcauPool.toFixed(2) }}<br />
            </h2>
          </div>
        </div>
        <div class="center">
          <img
            class="image"
            src="../../assets/dragon-fire-icon.png"
            alt="Dragon Fire"
          />
        </div>
        <div class="right">
          <h2>
            Prize Drawn on
            <span v-if="!isCurrentRoundFinished">
              Ends {{ lotteryEndTime }}
            </span>
            <span v-else>Round Finished!</span>
          </h2>
          <div class="date">
            {{ lotteryDraw }}
          </div>
        </div>
      </div>
      <div class="buy-tickets" v-if="!isCurrentRoundFinished">
        <h2 class="entries" v-if="roundDetails">
          <span>Total Entries </span>{{ roundEntryCount }}
          <template v-if="isSessionActive">
            | <span>Your Entries</span> {{ yourEntryCount }}</template
          >
        </h2>
        <div class="select-tickets" v-if="isSessionActive">
          <RoundButton
            :disabled="isBuyingWithDCAR || isBuyingWithDCAU"
            @click="decrementCount"
            >-</RoundButton
          >

          <input
            type="number"
            min="1"
            step="1"
            v-model="ticketCount"
            placeholder="Number of Tickets"
            :disabled="isBuyingWithDCAR || isBuyingWithDCAU"
          />
          <RoundButton
            :disabled="isBuyingWithDCAR || isBuyingWithDCAU"
            @click="incrementCount"
            >+</RoundButton
          >
        </div>
        <h3 v-if="!isSessionActive" style="margin-bottom: 0">
          Cost Per Ticket
        </h3>
        <div class="cost">
          <div class="balances col">
            <div class="dcar" id="dcau-balance">
              {{
                ticketCount == 0
                  ? dcarCost.toFixed(1)
                  : (dcarCost * ticketCount).toFixed(1)
              }}
            </div>
          </div>
          <div class="balances col">
            <div class="dcau" id="dcau-balance">
              {{
                ticketCount == 0
                  ? dcauCost.toFixed(1)
                  : (dcauCost * ticketCount).toFixed(1)
              }}
            </div>
          </div>
        </div>
        <div class="buy-buttons" v-if="isSessionActive">
          <BlueButton
            v-if="isDCARApproved"
            size="small"
            :isLoading="isBuyingWithDCAR"
            :disabled="
              isLoadingInitialData || isBuyingWithDCAR || isBuyingWithDCAU
            "
            @click="buyLotteryTicket('dcar')"
            >Buy With DCAR</BlueButton
          >
          <BlueButton
            v-else
            @click="approveDCAR"
            size="small"
            :isLoading="isApprovingDCAR"
            :disabled="
              isApprovingDCAU ||
              isApprovingDCAR ||
              isBuyingTicket ||
              isLoadingInitialData
            "
            >Approve DCAR</BlueButton
          >
          <BlueButton
            v-if="isDCAUApproved"
            size="small"
            :isLoading="isBuyingWithDCAU"
            :disabled="
              isLoadingInitialData || isBuyingWithDCAR || isBuyingWithDCAU
            "
            @click="buyLotteryTicket('dcau')"
            >Buy With DCAU</BlueButton
          >
          <BlueButton
            v-else
            @click="approveDCAU"
            size="small"
            :isLoading="isApprovingDCAU"
            :disabled="
              isApprovingDCAU ||
              isApprovingDCAR ||
              isBuyingTicket ||
              isLoadingInitialData
            "
            >Approve DCAU</BlueButton
          >
        </div>
        <div class="cost your-balances" v-if="isSessionActive">
          <div>
            Balance:
            <template v-if="!isFetchingDcarBalance"
              >{{ dcarBalance }} DCAR</template
            ><template v-else>
              <SkeletalLoading width="100" height="20" />
            </template>
          </div>
          <div>
            Balance:
            <template v-if="!isFetchingDcarBalance"
              >{{ dcauBalance }} DCAU</template
            ><template v-else>
              <SkeletalLoading width="100" height="20" />
            </template>
          </div>
        </div>
      </div>
      <br v-else />
      <hr />
      <div class="content-wrapper">
        <div class="col entries">
          <h2 v-if="roundDetails">
            {{ roundEntryCount }} <span> Total Entries</span>
          </h2>
        </div>
        <div class="col">
          <h2>
            {{ parseFloat(roundDetails.dcauBurned).toFixed(2) }}
            <span class="dcau">DCAU BURNED</span>
          </h2>
        </div>
        <div class="col">
          <h2>
            {{ parseFloat(roundDetails.dcarBurned).toFixed(2) }}
            <span class="burned"> DCAR BURNED</span>
          </h2>
        </div>
      </div>
      <hr />
      <div :class="['activity', toggleSidebar ? 'open' : 'closed']">
        <button @click="toggleSidebar = !toggleSidebar"></button>
        <div class="activity-container">
          <div v-if="!isLoadingActivity">
            <template v-if="activityList.length > 0">
              <div
                :class="[
                  'activity-entry',
                  item.count >= 2 && item.count < 5 ? 'medium' : '',
                  item.count >= 5 && item.count < 10 ? 'high' : '',
                  item.count >= 10 ? 'very-high' : '',
                ]"
                :key="item.count + index"
                v-for="(item, index) in activityList"
              >
                <span class="address">{{
                  avvyNameForAddress(item.roundEntryAddress)
                }}</span>
                purchased
                <span
                  :class="[
                    'count',
                    item.count >= 2 && item.count < 5 ? 'medium' : '',
                    item.count >= 5 && item.count < 10 ? 'high' : '',
                    item.count >= 10 ? 'very-high' : '',
                  ]"
                  >{{ item.count }}</span
                >
                ticket{{ item.count > 1 ? "s" : "" }}
                <span class="timestamp">{{
                  getReadableTimeStamp(item.timestamp)
                }}</span>
                <span v-if="item.count >= 10" class="star"> &#9733; </span>
              </div>
            </template>
            <p v-else>No Ticket Purchases Yet</p>
          </div>
          <GenericLoading style="position: absolute" v-else />
        </div>
      </div>
      <div class="desc">
        <div class="desc-container">
          <p>
            Hear ye, hear ye, step right up and try your luck at our thrilling
            Dragon Fire Lottery! This is your golden chance to win a spectacular
            reward as well as exciting bonuses from our prize pool. Entering is
            simple - just purchase tickets using your DCAU or DCAR tokens.
          </p>

          <p>
            Each entry gives you a chance to win but also fuels the excitement
            for others. With every ticket purchase, a portion of your tokens is
            burned, intensifying the stakes, while another portion is added to
            the swelling bonus prize pool.
          </p>

          <p>
            Remember, the more tickets you buy, the higher your chances of
            winning. The suspense reaches its peak on the draw date. So, what
            are you waiting for? Buy your tickets, ignite the competition, and
            you might just hit the jackpot!
          </p>

          <p>
            Please ensure to read and understand the game rules before
            participating. Enter responsibly. Good luck and may fortune favor
            you!
          </p>
        </div>
      </div>
      <hr />

      <div class="winner-list" v-if="winnerList.length > 0">
        <!-- winnerAddress: entry.winnerAddress,
        roundId: entry.roundId,
        timestamp: entry.blockTimestamp, -->
        <h2>🏆 Winners</h2>
        <div class="winner" :key="item.roundId" v-for="item in winnerList">
          <span class="round">Round {{ item.roundId }} winner is</span>
          <span class="address">{{
            avvyNameForAddress(item.winnerAddress)
          }}</span>
        </div>
      </div>
    </template>
  </div>
</template>
<script>
import { onMounted, ref, computed, onUnmounted } from "vue";
import { useStore } from "vuex";

// import { useRouter } from "vue-router";
import RoundButton from "../../components/Buttons/RoundButton.vue";
import BlueButton from "../../components/Buttons/BlueButton.vue";
import GenericLoading from "../../components/LoadingComponents/GenericLoading.vue";
import SkeletalLoading from "../../components/LoadingComponents/SkeletalLoading.vue";

import { ethers } from "ethers";
import { parseError } from "../../utils/helpers";
import { useUser } from "../../composables/user";
import { usePrice } from "../../composables/price";
import Constants from "../../consts/constants";

import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
dayjs.extend(relativeTime);

import {
  getDCGDragonFireLotteryContract,
  getDCARContract,
  getDCAUContract,
} from "../../utils/getContract";

import { useSound } from "../../composables/sound";
import trumpetSound from "../../assets/sound/trumpet.mp3";
import coinSpill from "../../assets/sound/coin-spill.mp3";

export default {
  displayName: "DragonFireLottery",
  components: {
    RoundButton,
    BlueButton,
    // ConnectButton,
    // Notifications,
    GenericLoading,
    SkeletalLoading,
  },
  setup() {
    const store = useStore();
    // const router = useRouter();
    const { address, signer } = useUser();
    const { getDCARBalance, getDCAUBalance, dcarBalance, dcauBalance } =
      usePrice();

    const prizeQuantity = ref(0);
    const prizeName = ref("");
    const prizeId = ref(null);
    const nftData = ref(null);
    const lotteryEnd = ref(null);
    const currentRound = ref(0);
    const lotteryDraw = ref(null);
    const dcauCost = ref(1);
    const dcarCost = ref(5);
    const ticketCount = ref(1);
    const maxTicketAllowed = ref(5);
    const roundEntryCount = ref(0);
    const yourEntryCount = ref(0);
    const roundDetails = ref(null);
    const startTime = ref(null);
    const dcarPool = ref(0);
    const dcauPool = ref(0);
    const activityList = ref([]);
    const winnerList = ref([]);

    // Loading related
    // const isLoading = ref(false);
    const isDCARApproved = ref(false);
    const isDCAUApproved = ref(false);
    const isLoadingInitialData = ref(true);
    const isFetchingDcarBalance = ref(true);
    const isApprovingDCAR = ref(false);
    const isApprovingDCAU = ref(false);
    const isBuyingWithDCAR = ref(false);
    const isBuyingWithDCAU = ref(false);
    const isBuyingTicket = ref(false);
    const isCurrentRoundFinished = ref(false);
    const isErc721Round = ref(false);
    const isErc20Round = ref(false);
    const isErc1155Round = ref(false);
    const isFetchingNftData = ref(false);
    const toggleSidebar = ref(true);
    const timeoutId = ref(null);
    const isLoadingActivity = ref(true);
    // const isBurningToken = ref(false);
    // const zeroAddress = ethers.constants.AddressZero;

    // Contract reactive variables
    const DragonFireLotteryContract = ref(null);
    const DCARContract = ref(null);
    const DCAUContract = ref(null);

    const playTrumpetSound = useSound(trumpetSound, {
      volume: 1,
      interrupt: true,
    });

    const playCoinSpillSound = useSound(coinSpill, {
      volume: 1,
      interrupt: true,
    });

    onMounted(async () => {
      // preloading sound files for better experience
      const trumpetSoundFile = new Audio(trumpetSound);
      const coinSpillSoundFile = new Audio(coinSpill);
      trumpetSoundFile.load();
      coinSpillSoundFile.load();

      timeoutId.value = setTimeout(() => {
        toggleSidebar.value = false;
      }, 10000);

      /**
       * Initialising contract instances if signer is available
       */
      if (isSessionActive.value) {
        await initialiseContractsAndGetData();
      } else {
        await getDataViaRPC();
      }
      subscribeToEntry();
      if (prizeId.value && isErc721Round.value) {
        await getERC721Data(prizeId.value);
      } else if (prizeId.value && isErc1155Round.value) {
        await getERC1155Data(prizeId.value);
      }
      // await getLatestPurchaseEvents();
      getLatestPurchaseEvents();
    });

    onUnmounted(() => {
      unWatchSessionActive();
      clearTimeout(timeoutId.value);
    });

    /**
     * Initialising contract instances when signer is available
     */
    const unWatchSessionActive = store.watch(
      () => store.state.isSessionActive,
      async (isSessionActive) => {
        if (isSessionActive) {
          await initialiseContractsAndGetData();
          // subscribeToDCARBurn();
        }
      }
    );

    const initialiseContractsAndGetData = async () => {
      isLoadingInitialData.value = true;
      if (isSessionActive.value) {
        // Initalising contract instances
        DragonFireLotteryContract.value = getDCGDragonFireLotteryContract(
          signer.value
        );
        DCARContract.value = getDCARContract(signer.value);
        DCAUContract.value = getDCAUContract(signer.value);

        // Getting DCAR Balance
        isFetchingDcarBalance.value = true;
        await getDCARBalance();
        await getDCAUBalance();
        isFetchingDcarBalance.value = false;

        await refreshData();

        // Checking if DCAR approved or not
        await checkIsDCARApproved();
        await checkIsDCAUApproved();
      }
      isLoadingInitialData.value = false;
    };

    /**
     * Fetch DATA using rpc provider instead of a signer
     */
    const getDataViaRPC = async () => {
      isLoadingInitialData.value = true;

      try {
        const rpcProvider = new ethers.providers.JsonRpcProvider(
          Constants.rpcUrl
        );
        DragonFireLotteryContract.value =
          getDCGDragonFireLotteryContract(rpcProvider);
        DCARContract.value = getDCARContract(rpcProvider);
        DCAUContract.value = getDCAUContract(rpcProvider);
        // Initalising contract using rpc provider
        await refreshData();
      } catch (error) {
        console.log(error);
      }
      isLoadingInitialData.value = false;
    };

    /**
     * Refresh Data
     */
    const refreshData = async () => {
      // Getting the current round
      const curRound = await DragonFireLotteryContract.value.currentRound();
      currentRound.value = ethers.utils.formatUnits(curRound, 0);

      isCurrentRoundFinished.value =
        await DragonFireLotteryContract.value.isCurrentRoundFinished();

      const round = await DragonFireLotteryContract.value.lotteryRounds(
        curRound
      );

      const costForDcau = await DragonFireLotteryContract.value.dcau();
      if (costForDcau) {
        dcauCost.value = parseFloat(
          ethers.utils.formatEther(costForDcau.entryPrice)
        );
      }

      const costForDcar = await DragonFireLotteryContract.value.dcar();
      if (costForDcar) {
        dcarCost.value = parseFloat(
          ethers.utils.formatEther(costForDcar.entryPrice)
        );
      }
      console.log(dcarCost.value);

      roundDetails.value = {
        dcarBurned: ethers.utils.formatEther(round.dcarBurned),
        dcarToTreasury: ethers.utils.formatEther(round.dcarToTreasury),
        dcauBurned: ethers.utils.formatEther(round.dcauBurned),
        dcauToTreasury: ethers.utils.formatEther(round.dcauToTreasury),
        duration: ethers.utils.formatUnits(round.duration, 0),
        erc20Prize: {
          amount: ethers.utils.formatEther(round.erc20Prize.amount),
          id: ethers.utils.formatUnits(round.erc20Prize.id, 0),
          prizeAddress: round.erc20Prize.prizeAddress,
        },
        erc721Prize: {
          amount: ethers.utils.formatUnits(round.erc721Prize.amount, 0),
          id: ethers.utils.formatUnits(round.erc721Prize.id, 0),
          prizeAddress: round.erc721Prize.prizeAddress,
        },
        erc1155Prize: {
          amount: ethers.utils.formatUnits(round.erc1155Prize.amount, 0),
          id: ethers.utils.formatUnits(round.erc1155Prize.id, 0),
          prizeAddress: round.erc1155Prize.prizeAddress,
        },
        hasCancelled: round.hasCancelled,
        hasDrawn: round.hasDrawn,
        startTime: ethers.utils.formatUnits(round.startTime, 0),
      };
      console.log("ROUND DETAILS", roundDetails.value);

      if (address.value) {
        const roundEntry =
          await await DragonFireLotteryContract.value.getRoundEntryCountByUser(
            currentRound.value,
            address.value
          );
        yourEntryCount.value = ethers.utils.formatUnits(roundEntry, 0);
      }
      if (
        roundDetails.value.erc20Prize.prizeAddress !==
        ethers.constants.AddressZero
      ) {
        isErc20Round.value = true;
        if (
          roundDetails.value.erc20Prize.prizeAddress ===
          DCARContract.value.address
        ) {
          prizeName.value = "DCAR";
        } else if (
          roundDetails.value.erc20Prize.prizeAddress ===
          DCAUContract.value.address
        ) {
          prizeName.value = "DCAU";
        }
        prizeQuantity.value = roundDetails.value.erc20Prize.amount;
      } else if (
        roundDetails.value.erc721Prize.prizeAddress !==
        ethers.constants.AddressZero
      ) {
        isErc721Round.value = true;
        prizeId.value = roundDetails.value.erc721Prize.id;
      } else if (
        roundDetails.value.erc1155Prize.prizeAddress !==
        ethers.constants.AddressZero
      ) {
        isErc1155Round.value = true;
        prizeId.value = roundDetails.value.erc1155Prize.id;
        prizeQuantity.value = roundDetails.value.erc1155Prize.amount;
      }

      console.log(dcarCost.value);

      const totalPrizeAmountDcar =
        await DragonFireLotteryContract.value.dcarPrizeForRound(curRound);
      dcarPool.value = parseFloat(
        ethers.utils.formatUnits(totalPrizeAmountDcar, 18)
      );

      const totalPrizeAmountDcau =
        await DragonFireLotteryContract.value.dcauPrizeForRound(curRound);
      dcauPool.value = parseFloat(
        ethers.utils.formatUnits(totalPrizeAmountDcau, 18)
      );

      roundEntryCount.value =
        await DragonFireLotteryContract.value.roundEntryCount(curRound);

      console.log(roundDetails.value);
      lotteryEnd.value =
        parseInt(roundDetails.value.startTime) +
        parseInt(roundDetails.value.duration);

      const tempDrawDate = new Date(lotteryEnd.value * 1000);
      const lotteryDrawDay = tempDrawDate.getDate();
      const lotteryDrawMonth = tempDrawDate.toLocaleString("default", {
        month: "long",
      });
      lotteryDraw.value = lotteryDrawDay + " " + lotteryDrawMonth;
      startTime.value = ethers.utils.formatUnits(
        roundDetails.value.startTime,
        0
      );
    };

    /**
     * Subscribing to the Burn Event fired from DCARBattleContract
     * RoundEntry( uint256 indexed roundId, address indexed roundEntryAddress );
     */
    const subscribeToEntry = () => {
      try {
        console.log("Subscribing to Lottery Purchase Event");
        const rpcProvider = new ethers.providers.JsonRpcProvider(
          Constants.rpcUrl
        );
        const lotteryContract = getDCGDragonFireLotteryContract(rpcProvider);
        lotteryContract.on(
          "RoundEntry",
          async (roundId, roundEntryAddress, count) => {
            activityList.value.push({ roundEntryAddress, count });
            console.log("ACTIVITY LIST", activityList.value);
            if (activityList.value.length > 30) {
              activityList.value.shift();
            }

            if (isSessionActive.value) {
              await initialiseContractsAndGetData();
            } else {
              await getDataViaRPC();
            }
          }
        );
      } catch (error) {
        console.log(error);
      }
    };

    const getLatestPurchaseEvents = async () => {
      isLoadingActivity.value = true;
      const response = await fetch(
        "https://api.thegraph.com/subgraphs/name/neothon/dragon-fire-lottery",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            query: `
            {
              roundEntries(first: 100, orderBy: blockNumber, orderDirection: desc, where: {roundId: ${currentRound.value}}
          ) {
                id
                roundEntryAddress
                count
                blockTimestamp
              }
            }
          `,
          }),
        }
      );

      const { data } = await response.json();
      console.log(data);
      activityList.value = data.roundEntries.map((entry) => ({
        roundEntryAddress: entry.roundEntryAddress,
        count: entry.count,
        timestamp: entry.blockTimestamp,
      }));
      isLoadingActivity.value = false;
    };

    const getWinnerList = async () => {
      const response = await fetch(
        "https://api.thegraph.com/subgraphs/name/neothon/dragon-fire-lottery",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            query: `
            {
              roundDrawns(orderBy: blockTimestamp, orderDirection: desc){
                blockTimestamp,
                winnerAddress,
                roundId
              }
            }
          `,
          }),
        }
      );

      const { data } = await response.json();
      console.log(data);
      winnerList.value = data.roundDrawns.map((entry) => ({
        winnerAddress: entry.winnerAddress,
        roundId: entry.roundId,
        timestamp: entry.blockTimestamp,
      }));
      console.log(winnerList.value);
    };

    /**
     * GEtting hero data
     * @param {} address
     */
    const getERC721Data = async (nftId) => {
      isFetchingNftData.value;
      console.log("ROUND DETAILS", roundDetails.value);
      let uri = null;
      // If Equipment ERC721
      if (
        roundDetails.value.erc721Prize.prizeAddress ===
        Constants.dcgEquipmentContract
      ) {
        uri = `https://heroes.dragoncrypto.io/metadata/equipment/${nftId}`;
      } else if (
        roundDetails.value.erc721Prize.prizeAddress ===
        Constants.heroMintContract
      ) {
        uri = `https://heroes.dragoncrypto.io/metadata/${nftId}`;
      }
      try {
        const response = await fetch(uri, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        });
        const data = await response.json();
        console.log("NFT DATA", data);
        nftData.value = data;
        console.log(data);
      } catch (error) {
        console.log(error);
      }
    };

    const getERC1155Data = async (id) => {
      isFetchingNftData.value;
      try {
        let uri = null;
        if (
          roundDetails.value.erc1155Prize.prizeAddress ===
          Constants.dcgResourceContract
        ) {
          uri = `https://heroes.dragoncrypto.io/metadata/resource/${id}`;
        } else if (
          roundDetails.value.erc1155Prize.prizeAddress ===
          Constants.dcgConsumablesContract
        ) {
          uri = `https://heroes.dragoncrypto.io/metadata/consumable/${id}`;
        } else if (
          roundDetails.value.erc1155Prize.prizeAddress ===
          Constants.lootChestsContract
        ) {
          uri = `https://heroes.dragoncrypto.io/metadata/lootchests/${id}`;
        }
        console.log(uri);
        const response = await fetch(uri, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        });
        const data = await response.json();
        console.log("NFT DATA", data);
        nftData.value = data;
        console.log(data);
      } catch (error) {
        console.log(error);
      }
    };

    const avvyNameForAddress = (address) => {
      store.commit("avvynames/getOrSetAvvyName", address);
      return store.state.avvynames.addressToNames[address];
    };

    const incrementCount = () => {
      if (ticketCount.value < maxTicketAllowed.value) {
        ticketCount.value++;
      }
    };
    const decrementCount = () => {
      ticketCount.value--;
      if (ticketCount.value === 0) {
        ticketCount.value = 1;
      }
    };

    /**
     * Buy with DCAR
     */
    const buyLotteryTicket = async (token) => {
      token === "dcar"
        ? (isBuyingWithDCAR.value = true)
        : (isBuyingWithDCAU.value = true);
      try {
        if (ticketCount.value > 0) {
          // Executing enter round Transaction
          const tx = await DragonFireLotteryContract.value.enterCurrentRound(
            token === "dcar",
            ethers.utils.parseUnits(`${ticketCount.value}`, 0)
          );
          isBuyingTicket.value = true;
          playCoinSpillSound.play();
          const receipt = await tx.wait();
          // Only process Heal if the transaction was successful!
          if (receipt.status === 1) {
            if (token === "dcar") {
              await getDCARBalance(); // updating token balances
            } else if (token === "dcau") {
              await getDCAUBalance(); // updating token balances
            }
          }
          isBuyingTicket.value = false;
          playCoinSpillSound.stop();
          playTrumpetSound.play();
          store.commit(
            "setNotification",
            `You successfully purchased ${ticketCount.value} tickets`
          );
          ticketCount.value = 1;
          //Updating data
          await refreshData();
        } else {
          ticketCount.value = 1;
          store.commit("setNotification", "Please select atleast 1 ticket");
          return;
        }
      } catch (error) {
        console.log(error);
        store.commit("setNotification", parseError(error));
      }
      isBuyingWithDCAR.value = false;
      isBuyingWithDCAU.value = false;
    };

    const getReadableTimeStamp = (timestamp) => {
      if (timestamp) {
        const d = new Date(timestamp * 1000);
        return dayjs(d).fromNow();
      }
      return null;
    };

    /**
     * Method to verify of DCAR is pre-approved
     */
    const checkIsDCARApproved = async () => {
      let allowanceDCARLottery = await DCARContract.value.allowance(
        address.value,
        DragonFireLotteryContract.value.address
      );
      allowanceDCARLottery = ethers.utils.formatEther(allowanceDCARLottery);
      console.log(allowanceDCARLottery);
      if (
        parseFloat(allowanceDCARLottery) >= parseFloat(dcarCost.value) &&
        parseFloat(allowanceDCARLottery) > 0.0
      ) {
        isDCARApproved.value = true;
      } else {
        isDCARApproved.value = false;
      }
    };
    /**
     * Method to approve DCAR for the Lottery contract
     */
    const approveDCAR = async () => {
      isApprovingDCAR.value = true;
      try {
        let txApproveMarketplaceSpend = await DCARContract.value.approve(
          DragonFireLotteryContract.value.address,
          ethers.utils.parseEther("1000000000")
        );
        const receipt = await txApproveMarketplaceSpend.wait();
        if (receipt.status === 1) {
          const allowanceDragonLottery = ethers.utils.formatEther(
            receipt.events[0].args.value
          );
          isDCARApproved.value =
            parseFloat(allowanceDragonLottery) >= parseFloat(dcarCost.value);
        }
      } catch (error) {
        store.commit("setNotification", parseError(error));
      }
      isApprovingDCAR.value = false;
    };
    /**
     * Method to verify of DCAU is pre-approved
     */
    const checkIsDCAUApproved = async () => {
      let allowanceDragonLottery = await DCAUContract.value.allowance(
        address.value,
        DragonFireLotteryContract.value.address
      );
      allowanceDragonLottery = ethers.utils.formatEther(allowanceDragonLottery);

      if (
        parseFloat(allowanceDragonLottery) >= parseFloat(dcauCost.value) &&
        parseFloat(allowanceDragonLottery) > 0.0
      ) {
        isDCAUApproved.value = true;
      } else {
        isDCAUApproved.value = false;
      }
      console.log(isDCARApproved.value);
    };
    /**
     * Method to approve DCAU for the Lottery contract
     */
    const approveDCAU = async () => {
      isApprovingDCAU.value = true;
      try {
        let txLotterySpend = await DCAUContract.value.approve(
          DragonFireLotteryContract.value.address,
          ethers.utils.parseEther("1000000000")
        );
        const receipt = await txLotterySpend.wait();
        if (receipt.status === 1) {
          const allowanceDragonLottery = ethers.utils.formatEther(
            receipt.events[0].args.value
          );
          isDCAUApproved.value =
            parseFloat(allowanceDragonLottery) >= parseFloat(dcauCost.value);
        }
      } catch (error) {
        store.commit("setNotification", parseError(error));
      }
      isApprovingDCAU.value = false;
    };

    const isSessionActive = computed(() => {
      return store.state.isSessionActive;
    });

    const lotteryEndTime = computed(() => {
      if (lotteryEnd.value) {
        const d = new Date(lotteryEnd.value * 1000);
        return dayjs(d).fromNow();
      }
      return null;
    });

    getWinnerList();

    return {
      // data
      isLoadingInitialData,
      prizeQuantity,
      prizeName,
      prizeId,
      lotteryEnd,
      isErc20Round,
      isErc721Round,
      isErc1155Round,
      isDCAUApproved,
      isBuyingWithDCAU,
      isApprovingDCAU,
      isDCARApproved,
      isApprovingDCAR,
      isBuyingWithDCAR,
      ticketCount,
      roundEntryCount,
      yourEntryCount,
      isBuyingTicket,
      roundDetails,
      startTime,
      lotteryDraw,
      dcauCost,
      dcarCost,
      dcarPool,
      dcauPool,
      activityList,
      dcarBalance,
      dcauBalance,
      isFetchingDcarBalance,
      nftData,
      isCurrentRoundFinished,
      toggleSidebar,
      winnerList,
      isLoadingActivity,
      // method
      approveDCAR,
      approveDCAU,
      incrementCount,
      decrementCount,
      buyLotteryTicket,
      avvyNameForAddress,
      getReadableTimeStamp,
      // computed
      lotteryEndTime,
      isSessionActive,
    };
  },
};
</script>
<style lang="scss" scoped>
video.coins {
  opacity: 0;
  /* transform: translateY(100%); */
  transition: all 0.5s ease;
  width: 100%;
  height: 100%;
  object-fit: cover;
  top: 0;
  position: absolute;
  left: 0;
  display: none;
  @media only screen and (max-width: 576px) {
    bottom: 0;
    height: 100vh;
    width: auto;
    object-fit: cover;
    position: fixed;
  }
}
.animate-coins {
  background: transparent;
  .header {
    opacity: 0;
  }

  .bg {
    transform: scale(1.5) translate(60px, 100px);
  }
  .white-box {
    opacity: 0;
  }
  .image,
  .col,
  .desc,
  hr {
    display: none !important;
  }

  video.coins {
    opacity: 0.7;
    display: block;
    /* transform: translateY(0); */
  }
}
.bg {
  background: #2c1c10 url("../../assets/burning-blur.png");
  background-position: top;
  background-attachment: fixed;
  background-size: cover;
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: -1;
  transition: all 1s ease;
}
.dragon-fire-wrapper {
  width: 1200px;
  height: 100%;
  margin: 0 auto;
  padding-top: 200px;
  padding-bottom: 100px;
  @media only screen and (max-width: 576px) {
    width: 100%;
  }
  &.animate-coins {
    .top-area {
      .left {
        animation: hideRight 0.1s ease-in-out forwards;
      }
      .right {
        animation: hideLeft 0.1s ease-in-out forwards;
      }
    }
    .buy-tickets {
      opacity: 0;
    }
  }
  h1.title {
    font-size: 2.5rem;
  }
  .main-prize {
    margin-bottom: 6rem;
    font-size: 1.5rem;
    opacity: 0.7;
  }
  .entries {
    font-size: 1.5rem;
    text-transform: capitalize;
    span {
      margin: 0 5px;
      display: inline-block;
      opacity: 0.5;
    }
  }
  .top-area {
    display: grid;
    width: 100%;
    grid-template-columns: 1fr 200px 1fr;
    @media only screen and (max-width: 576px) {
      grid-template-columns: 1fr;
    }
    .left,
    .right {
      position: relative;
      background: rgb(35 35 35 / 49%);
      backdrop-filter: blur(2px);
      height: 100%;
      min-height: calc(150px + 2rem);
      display: grid;
      align-items: center;
      justify-items: center;
      transition: all 0.1s ease-in-out;
      @media only screen and (max-width: 576px) {
        margin-bottom: 20px;
      }
      .image {
        width: 200px;
        height: 200px;
        position: absolute;
        background: #000;
        display: grid;
        align-items: center;
        justify-items: center;
        left: -75px;
        border-radius: 100%;
        padding: 1rem;
        top: -22px;
        border: 1px #ffce0080 solid;
        @media only screen and (max-width: 576px) {
          position: relative;
          margin: 20px auto;
          top: 0;
          left: 0;
        }
        img {
          width: 100%;
        }
        .cover-image {
          display: block;
          width: calc(100% + 2rem);
          height: calc(100% + 2rem);
          background-size: contain;
          border-radius: 100%;
        }
      }
      .date {
        width: 200px;
        height: 200px;
        position: absolute;
        background: #000;
        display: grid;
        align-items: center;
        justify-items: center;
        right: -75px;
        border-radius: 100%;
        padding: 1rem;
        top: -22px;
        font-size: 3rem;
        border: 1px #ffce0080 solid;
        @media only screen and (max-width: 576px) {
          position: relative;
          margin: 20px auto;
          top: 0;
          right: 0;
        }
      }
      h2 {
        margin: 0;
        &.prize {
          font-size: 2rem;
          span {
            margin: 0 5px;
            display: inline-block;
          }
        }
        span {
          font-size: 1rem;
          opacity: 0.5;
          display: block;
          margin: 1rem 0 0;
          &.dcar {
            color: #c0c0c0;
          }

          &.dcau {
            color: #e3d67c;
          }
        }
      }
    }
    .right {
      width: calc(100% - 100px);
      padding-right: 100px;
      @media only screen and (max-width: 576px) {
        width: 100%;
        padding-right: 0;
      }
    }
    .left {
      width: calc(100% - 100px);
      padding-left: 100px;
      @media only screen and (max-width: 576px) {
        width: 100%;
        padding-left: 0;
      }
    }
    .center {
      position: relative;
      img {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        z-index: 10;
        @media only screen and (max-width: 576px) {
          display: none;
        }
      }
    }
  }
  .buy-tickets {
    display: grid;
    align-items: center;
    justify-items: center;
    margin: 5rem 0 0;
    width: 100%;
    transition: all 0.5s ease;
    .cost {
      display: grid;
      grid-template-columns: 1fr 1fr;
      width: 35%;
      justify-items: center;
      align-items: center;
      margin: 2rem 0 0;
      @media only screen and (max-width: 576px) {
        width: 95%;
      }
      .label {
        font-size: 2rem;
      }
    }
    .your-balances {
      margin: 5px 0 0;
      font-size: 0.8rem;
      opacity: 0.6;
      font-family: "Lato", sans-serif;
    }

    .buy-buttons {
      width: 35%;
      display: flex;
      margin: 0 auto;
      @media only screen and (max-width: 576px) {
        width: 95%;
      }
    }
  }
  hr {
    margin: 4rem 0;
    border: 1px solid #ffffff24;
  }
  .content-wrapper {
    width: 100%;
    display: grid;
    /* grid-template-columns: 1fr 1fr 1fr; */
    grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
    @media only screen and (max-width: 576px) {
      grid-template-columns: 1fr;
    }
    margin: 0;
    grid-gap: 2rem;
    align-items: center;
    .col {
      background: #000000ba;
      backdrop-filter: blur(2px);
      width: calc(100% - 2rem);
      height: calc(100% - 5rem);
      display: grid;
      padding: 1rem;
      align-items: center;
      justify-items: center;
      overflow: hidden;
      border-radius: 10px;
      box-shadow: 0 0 3px 2px rgb(0 0 0 / 37%);
      @media only screen and (max-width: 576px) {
        height: 100%;
        margin-bottom: 20px;
      }
      &:after {
        content: "";
        width: 100%;
        height: 100%;
        position: absolute;
        background-repeat: no-repeat;
        background-position: center center;
        opacity: 0.05;
        right: -20%;
        bottom: -20%;
      }
      &.prize:after {
        background-image: url("../../assets/trophy.svg");
        background-size: contain;
      }
      &.date:after {
        background-image: url("../../assets/date.svg");
        background-size: contain;
        right: -15%;
        bottom: -15%;
      }
      &.prize,
      &.entries {
        h2 {
          font-size: 4rem;
          margin: 0;
          span {
            /* font-size: 2.5rem !important; */
            margin: 0;
          }
        }
      }
      h2 {
        font-size: 3rem;
        /* font-family: "Lato", sans-serif; */
        span {
          font-size: 1.5rem;
          opacity: 0.5;
          display: block;
          margin: 1rem 0;
          &.dcar {
            color: #c0c0c0;
          }

          &.dcau {
            color: #e3d67c;
          }
        }
      }
      .image {
        width: 80%;
      }
    }
  }
}
.content-box {
  position: relative;
  width: 100%;
  text-align: left;
  margin: 0 auto 50px;
  display: block;
  background: #ffffff76;
  backdrop-filter: blur(1.3px);
  border-radius: 2px;
  box-shadow: 0 0 2px 2px #00000045;
  .wrapper {
    padding: 16px;
    display: flex;
  }

  &.black-bg {
    background: rgba(0, 0, 0, 0.517);
    border-radius: 6px;
  }

  .centered {
    text-align: center;
  }

  .half-box {
    width: 50%;
  }

  .image {
    position: absolute;
    top: -10px;
    right: -10px;
  }

  .dcar {
    color: #c0c0c0;
  }

  .dcau {
    color: #e3d67c;
  }

  .burned {
    color: #febe9a;
  }

  .time {
    color: #e98888;
  }
}
.grid {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  width: calc(100% - 150px);
  left: 150px;
  position: relative;
  margin-bottom: 20px;
}
.select-tickets {
  display: grid;
  grid-template-columns: 50px 100px 50px;
  input[type="number"] {
    font-size: 1.2rem;
    width: calc(100% - 16px);
    margin: 1px 5px;
    padding: 3px;
    border: none;
    border-radius: 5px;
    box-shadow: 0 0 2px 2px #0000003b;
    text-align: center;
    &::-webkit-inner-spin-button,
    &::-webkit-outer-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
    -moz-appearance: textfield;
  }
  button {
    &:active {
      background-position: center -100px;
    }
  }
}

.balances {
  div {
    display: block;
    align-items: center;
    justify-items: flex-start;
    font-size: 1.4rem;
    margin: 11px 0;
    font-family: "Lato", sans-serif;
    color: #ffffff;
    span {
      font-size: 0.8rem;
      opacity: 0.7;
    }
    &:before {
      background-size: contain;
      content: "";
      display: inline-block;
      width: 35px;
      height: 35px;
      line-height: 0;
      margin-top: -13px;
      padding: 0;
      top: 13px;
      position: relative;
      margin-right: 4px;
    }
    p {
      margin: 0;
      span {
        font-size: 0.7rem;
        display: block;
        margin: 2px 0 0;
        color: #ffffff85;
      }
    }
  }
  .dcau {
    &:before {
      background: url("../../assets/ui/dcau.png") no-repeat left center;
      background-size: contain;
    }
  }
  .dcar {
    &:before {
      background: url("../../assets/ui/dcar.png") no-repeat left center;
      background-size: contain;
    }
  }
}
.activity {
  position: fixed;
  right: 0;
  height: calc(100vh - 138px);
  display: block;
  background: #211813;
  border-right: 1px rgba(255, 206, 0, 0.5019607843) solid;
  width: 310px;
  top: 138px;
  z-index: 49;
  left: -310px;
  box-shadow: 1px 0 6px 5px #00000075;
  transition: all 0.5s ease;

  .activity-container {
    height: calc(100% - 2rem);
    padding: 1rem 10px;
    position: relative;
    overflow-y: scroll;

    &::-webkit-scrollbar {
      width: 5px;
    }

    &::-webkit-scrollbar-track {
      background: transparent;
    }

    &::-webkit-scrollbar-thumb {
      background: #000;

      &:hover {
        background: #555;
      }
    }
  }
  &.open {
    left: 0;
    button {
      background: url("../../assets/ui/scrollbar-left.png") no-repeat left
        center;

      background-size: cover;
    }
  }
  button {
    position: absolute;
    right: -40px;
    top: 30%;
    transform: translateY(-50%);
    width: 40px;
    height: 40px;
    box-shadow: none;
    outline: none;
    border: none;
    border-radius: 100%;
    display: grid;
    align-items: center;
    justify-items: center;
    background: url("../../assets/ui/scrollbar-right.png") no-repeat left center;
    background-size: cover;
    cursor: url("https://cdn.dragoncrypto.io/uiassets/gauntlet_pointy_cursor_gray.png"),
      auto;
  }
  .activity-entry {
    font-size: 1rem;
    margin: 0 0 10px;
    text-align: left;
    font-family: "Lato", sans-serif;
    color: #919191;
    background: rgb(0 0 0 / 20%);
    padding: 0.5rem;
    border-radius: 5px;
    position: relative;
    &.very-high {
      background-color: rgb(255 242 30 / 6%);
    }
    span.star {
      font-size: 15px;
      color: #ffde00;
      position: absolute;
      top: -7px;
      left: -2px;
      animation: float 2s ease-in-out infinite;
    }

    span.timestamp {
      font-size: 0.7rem;
      display: block;
      opacity: 0.6;
    }
    .address {
      color: #fff;
    }
    .count {
      padding: 0 4px;
      font-size: 0.9rem;
      background-color: #9c9c9c;
      border-radius: 3px;
      color: #000;
      /* &.medium {
        background-color: #bbc4fc;
      } */
      &.high {
        background-color: #ffe600;
      }
      &.very-high {
        background-color: #b70000;
        color: #fff;
      }
    }
  }
}
.desc {
  .desc-container {
    column-count: 2;
    column-gap: 20px;
    font-size: 1.4rem;
    text-align: left;
    p {
      margin-top: 0;
    }
    @media only screen and (max-width: 576px) {
      padding: 0 1rem;
      column-count: 1;
    }
  }
}
.winner-list {
  .winner {
    color: #fff;
    margin-bottom: 20px;
    .round {
      opacity: 0.7;
      font-size: 1.5rem;
      margin-right: 10px;
    }
    .address {
      font-size: 1.8rem;
    }
    .timestamp {
      display: block;
      font-size: 1rem;
      opacity: 0.5;
    }
  }
}
@keyframes hideLeft {
  0% {
    transform: translateX(0);
    opacity: 1;
  }
  100% {
    padding: 0;
    width: 0;
    transform: translateX(-50%);
    opacity: 0;
  }
}
@keyframes hideRight {
  0% {
    transform: translateX(0);
    opacity: 1;
  }
  100% {
    padding: 0;
    width: 0;
    transform: translateX(50%);
    opacity: 0;
  }
}
@keyframes float {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.2);
  }
  100% {
    transform: scale(1);
  }
}
</style>
